import React from 'react';

const ProductManagementPage = () => {
  return (
    <div className="container mt-5 mb-5">
      <div className="row"style={{marginTop:'8em'}}>
        <div className="col-md-6 mb-4">
          <div className="card bg-light shadow rounded">
            <div className="card-body">
              <h2 className="card-title text-primary mb-4" >Our Approach to Product Management</h2>
              <p className="card-text">At Our Company, we believe in a strategic approach to product management that focuses on understanding market needs, aligning with business goals, and delivering value to customers.</p>
              <p className="card-text">We work closely with stakeholders to define product vision, roadmap, and priorities. Utilizing agile methodologies, we ensure rapid delivery and adaptation to changing market conditions.</p>
              <p className="card-text">Whether you're launching a new product or optimizing an existing one, we're here to help you navigate the complexities of product management and achieve your business objectives.</p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card bg-primary text-white shadow rounded h-100">
            <div className="card-body">
              <h2 className="card-title mb-4">Transform Your Ideas into Success</h2>
              <p className="card-text">Unlock the potential of your products with our strategic product management solutions. From conceptualization to launch, we're here to guide you every step of the way.</p>
              <p className="card-text">Let's work together to bring your vision to life and drive your business forward.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="card bg-light shadow rounded">
            <div className="card-body">
              <h2 className="card-title text-primary mb-4">Our Product Management Services</h2>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">Product Strategy Development</li>
                <li className="list-group-item">Market Research and Analysis</li>
                <li className="list-group-item">Product Roadmapping</li>
                <li className="list-group-item">Agile Product Development</li>
                <li className="list-group-item">Product Launch and Optimization</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductManagementPage;
