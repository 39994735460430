import React from 'react';

const CompanyCulture = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4 culture-heading" style={{ color: '#59058b' ,margin:'4em'}}>Company Culture</h2>
      <div className="row">
        <div className="col-lg-4">
          <div className="culture-card" style={{ backgroundColor: '#F8F9FA', borderRadius: '8px', padding: '20px', marginBottom: '20px' }}>
            <span className="bi bi-people culture-icon"></span>
            <h3 className="culture-title" style={{ color: '#673AB7', marginBottom: '10px' }}>Collaborative Environment</h3>
            <p className="culture-text" style={{ color: '#333' }}>We foster a collaborative and inclusive environment where every team member is valued and encouraged to contribute their ideas. Regular team meetings, brainstorming sessions, and open communication channels are some of the ways we promote collaboration.</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="culture-card" style={{ backgroundColor: '#F8F9FA', borderRadius: '8px', padding: '20px', marginBottom: '20px' }}>
            <span className="bi bi-globe2 culture-icon"></span>
            <h3 className="culture-title" style={{ color: '#673AB7', marginBottom: '10px' }}>Diversity and Inclusion</h3>
            <p className="culture-text" style={{ color: '#333' }}>We celebrate diversity and believe in creating an inclusive workplace where everyone feels respected and empowered to succeed. We actively promote diversity in hiring practices, organize cultural awareness workshops, and support employee resource groups.</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="culture-card" style={{ backgroundColor: '#F8F9FA', borderRadius: '8px', padding: '20px', marginBottom: '20px' }}>
            <span className="bi bi-book culture-icon"></span>
            <h3 className="culture-title" style={{ color: '#673AB7', marginBottom: '10px' }}>Continuous Learning</h3>
            <p className="culture-text" style={{ color: '#333' }}>We support continuous learning and development through workshops, training programs, and opportunities for skill enhancement. Employees are encouraged to pursue certifications, attend industry conferences, and participate in online courses to stay updated with the latest trends and technologies.</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="culture-card" style={{ backgroundColor: '#F8F9FA', borderRadius: '8px', padding: '20px', marginBottom: '20px' }}>
            <span className="bi bi bi-reception-3 culture-icon"></span>
            <h3 className="culture-title" style={{ color: '#673AB7', marginBottom: '10px' }}>Work-Life Balance</h3>
            <p className="culture-text" style={{ color: '#333' }}>We believe in promoting a healthy work-life balance, offering flexible schedules and remote work options to support our employees' well-being. We encourage employees to take breaks, use their vacation days, and prioritize their mental and physical health.</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="culture-card" style={{ backgroundColor: '#F8F9FA', borderRadius: '8px', padding: '20px', marginBottom: '20px' }}>
            <span className="bi bi-gem culture-icon"></span>
            <h3 className="culture-title" style={{ color: '#673AB7', marginBottom: '10px' }}>Innovation</h3>
            <p className="culture-text" style={{ color: '#333' }}>We encourage innovation and creativity in everything we do. Our culture fosters an environment where new ideas are welcomed, and employees are empowered to take risks and think outside the box to drive innovation.</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="culture-card" style={{ backgroundColor: '#F8F9FA', borderRadius: '8px', padding: '20px', marginBottom: '20px' }}>
            <span className="bi bi-lightbulb culture-icon"></span>
            <h3 className="culture-title" style={{ color: '#673AB7', marginBottom: '10px' }}>Creativity</h3>
            <p className="culture-text" style={{ color: '#333' }}>We value creativity and encourage our employees to bring their unique perspectives and ideas to the table. Our culture promotes a creative mindset and provides resources and support for employees to explore new approaches and solutions.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCulture;
