import React, { useState } from 'react';
import p1 from '../assets/img/portfolio/1.png';
import p2 from '../assets/img/portfolio/2.webp';
import p3 from '../assets/img/portfolio/3.png';
import p4 from '../assets/img/portfolio/4.jpg';
import p5 from '../assets/img/portfolio/5.jpg';
import p6 from '../assets/img/portfolio/6.jpg';
import p7 from '../assets/img/portfolio/7.png';
import p8 from '../assets/img/portfolio/8.png';
import p9 from '../assets/img/portfolio/9.png';
import p10 from '../assets/img/portfolio/10.jpg';
import p11 from '../assets/img/portfolio/11.webp';


function Product() {
  const [filter, setFilter] = useState('all');

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredItems = [
    { img: p1, category: 'E-commerce' ,info:"Cultivate convenience, inspire confidence, and elevate experiences - your gateway to seamless commerce in the digital realm."},
    { img: p2, category: 'E-commerce' ,info:"Empower your business with our cutting-edge Ecommerce solutions, tailored to amplify your online presence and drive unprecedented growth in the digital marketplace."},
    { img: p3, category: 'ERP' ,info:"Unlock the full potential of your business with our ERP solutions, seamlessly integrating processes, optimizing workflows, and providing real-time insights for smarter, more efficient operations. Elevate your enterprise to new heights with our cutting-edge ERP technology."},
    { img: p4, category: 'ERP',info: "Transform your business operations with our cutting-edge ERP solution, streamlining processes, enhancing efficiency, and driving strategic decision-making for sustainable growth and competitive advantage."},
    { img: p5, category: 'HealthCare' ,info:"Transforming healthcare through innovation, our solutions prioritize patient-centric care, operational efficiency, and data-driven insights. Empower your healthcare journey with our advanced technology, ensuring a healthier and connected future for all."},
    { img: p6, category: 'HealthCare',info: "Transforming healthcare delivery with our innovative solutions, focused on improving patient outcomes, enhancing operational efficiency, and fostering collaboration across the healthcare ecosystem."},
    { img: p7, category: 'Insurance',info:"Navigate the complexities of risk management with our tailored insurance solutions. We safeguard your future by providing comprehensive coverage, innovative risk assessments, and personalized service, ensuring peace of mind in an ever-changing world." },
    { img: p8, category: 'Insurance' ,info:"Secure your future with our tailored insurance solutions, providing peace of mind and financial protection against life's uncertainties. From comprehensive coverage to personalized advice, we're here to safeguard what matters most to you."},
    { img: p9, category: 'E-commerce' ,info:"Empower your business with our comprehensive Ecommerce service, where technology meets seamless customer experiences to drive growth and success in the digital marketplace."},
    { img: p10, category: 'HealthCare' ,info:"Transform healthcare delivery with our innovative solutions, leveraging cutting-edge technology to improve patient outcomes, optimize operational efficiency, and empower healthcare professionals to deliver exceptional care with compassion and precision."},
    { img: p11, category: 'ERP' ,info:"Streamline your business operations and enhance productivity with our comprehensive ERP solution, designed to integrate seamlessly across all departments and provide real-time insights for informed decision-making."},

    // Add other items with their categories...
  ].filter(item => filter === 'all' || item.category === filter);

  return (
    <section id="portfolio" className="portfolio">
      <div className="container" style={{ marginTop: '80px' }}>
        <div className="section-title" style={{ textAlign: 'center' }}>
          <h2>Product</h2>
          <p>Check our Product</p>
        </div>

        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li onClick={() => handleFilterChange('all')} className={filter === 'all' ? 'filter-active' : ''}>All</li>
              <li onClick={() => handleFilterChange('E-commerce')} className={filter === 'E-commerce' ? 'filter-active' : ''}>E-commerce</li>
              <li onClick={() => handleFilterChange('ERP')} className={filter === 'ERP' ? 'filter-active' : ''}>ERP</li>
              <li onClick={() => handleFilterChange('HealthCare')} className={filter === 'HealthCare' ? 'filter-active' : ''}>HealthCare</li>
              <li onClick={() => handleFilterChange('Insurance')} className={filter === 'Insurance' ? 'filter-active' : ''}>Insurance</li>
              {/* Add other filter options */}
            </ul>
          </div>
        </div>

        <div className="row portfolio-container">
          {filteredItems.map((item, index) => (
            <div key={index} className={`col-lg-4 col-md-6 portfolio-item filter-${item.category}`}>
              <img src={item.img} className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h4>{item.category}</h4>
                <p>{item.info}</p>
                <a href={item.img} data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title={`App ${index + 1}`}><i className="bx bx-plus"></i></a>
                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Product;
