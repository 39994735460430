import React, { useState } from 'react';

const OnboardingProcess = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <div className="container mt-5" >
      <div className="card" style={{ margin:'12em 0 15em 0'}}>
        <div className="card-body" style={{backgroundColor:'#FEECE2',padding:'10%'}}>
          {step === 1 && (
            <div>
              <h2 style={{color:'#ff8960'}}>Step 1: Pre-Arrival Preparation</h2>
              <li>Send a welcome email to the new employee with essential information such as start date, time, location, dress code (if applicable), and any required documentation.</li><li>
Provide information about parking, public transportation, or any other logistics relevant to their first day.</li>
              <button className="btn btn-primary" onClick={nextStep}>Next</button>
            </div>
          )}
          {step === 2 && (
            <div>
              <h2 style={{color:'#ff8960'}}>Step 2: First Day Orientation</h2>
              <li>Welcome the new employee on their first day and introduce them to the team.</li><li>
Provide a tour of the workplace, including facilities, restrooms, cafeteria, and other common areas.</li><li>
Set up their workstation with necessary equipment such as a computer, phone, and stationery.
Review the employee handbook and company policies.</li><li>
Conduct an overview of the company's mission, values, and organizational structure.</li>
              <button className="btn btn-secondary mr-2" onClick={prevStep}>Previous</button>
              <button className="btn btn-primary" onClick={nextStep}>Next</button>
            </div>
          )}
          {step === 3 && (
            <div>
              <h2 style={{color:'#ff8960'}}>Step 3: HR Paperwork and Benefits Enrollment</h2>
              <li>Assist the new employee in completing necessary HR paperwork, including tax forms, direct deposit setup, and confidentiality agreements.</li><li> Provide information about employee benefits such as health insurance, retirement plans, and any other perks offered by the company.</li>
              <button className="btn btn-secondary mr-2" onClick={prevStep}>Previous</button>
              <button className="btn btn-primary" onClick={nextStep}>Next</button>
            </div>
          )}
           {step === 4 && (
            <div>
              <h2 style={{color:'#ff8960'}}>Step 4: Training and Onboarding Sessions</h2>
              <li>Schedule training sessions to familiarize the new employee with their role, responsibilities, and expectations.</li><li>
Provide access to training materials, online courses, or other resources to facilitate learning.</li><li>
Assign a mentor or buddy to guide the new employee and answer any questions they may have.</li>
              <button className="btn btn-secondary mr-2" onClick={prevStep}>Previous</button>
              <button className="btn btn-primary" onClick={nextStep}>Next</button>
            </div>
          )}
           {step === 5 && (
            <div>
              <h2 style={{color:'#ff8960'}}>Step 5:Introduction to Tools and Systems</h2>
              <li>Provide access to necessary tools, software, and systems required for the job.
Offer training sessions or tutorials on how to use these tools effectively.</li>
              <button className="btn btn-secondary mr-2" onClick={prevStep}>Previous</button>
              <button className="btn btn-primary" onClick={nextStep}>Next</button>
            </div>
          )}
           {step === 6 && (
            <div>
              <h2 style={{color:'#ff8960'}}>Step 6: Team Integration</h2>
              <li>Facilitate introductions to team members and key stakeholders.</li><li>
Schedule one-on-one meetings with team members to foster relationships and provide an opportunity for the new employee to ask questions.</li>
              <button className="btn btn-secondary mr-2" onClick={prevStep}>Previous</button>
              <button className="btn btn-primary" onClick={nextStep}>Next</button>
            </div>
          )}
           {step === 7 && (
            <div>
              <h2 style={{color:'#ff8960'}}>Step 7: Clarification of Expectations</h2>
              <li>Clearly communicate performance expectations, goals, and objectives for the new employee's role.</li><li>
Discuss metrics for success and how performance will be evaluated.</li>
              <button className="btn btn-secondary mr-2" onClick={prevStep}>Previous</button>
              <button className="btn btn-primary" onClick={nextStep}>Next</button>
            </div>
          )}
           {step === 8 && (
            <div>
              <h2 style={{color:'#ff8960'}}>Step 8:Feedback and Check-Ins</h2>
              <li>Schedule regular check-ins to provide feedback, answer questions, and address any concerns the new employee may have.</li><li>
Encourage open communication and create a supportive environment for the new employee to share their thoughts and ideas.</li>
              <button className="btn btn-secondary mr-2" onClick={prevStep}>Previous</button>
              <button className="btn btn-secondary mr-2" style={{backgroundColor:'green'}}>Done</button>
            </div>
          )}
          
        </div>
      </div>
    </div>
  );
};

export default OnboardingProcess;
