import React from 'react';
import webDesignImage from '../assets/img/what-is-web-design.webp'; // Import your web design image here

const WebDesignPage = () => {
  return (
    <div className="container mt-4">
      <h1 className="text-primary mb-4" style={{marginTop:'3em'}}>Web Design Services</h1>
      <section style={{ marginBottom: '30px' }}>
        <div className="row">
          <div className="col-md-6">
          <img
              src={webDesignImage}
              alt="Web Design"
              className="img-fluid rounded"
              style={{ marginBottom: '20px', maxWidth: '100%', height: 'auto', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}
            />
          </div>
          <div className="col-md-6">
            <h2 style={{ color: '#007bff', marginBottom: '20px' }}>Our Approach to Web Design</h2>
            <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px' }}>
              At Our Company, we approach web design as a blend of art and science. Our mission is to create visually stunning websites that are not only aesthetically pleasing but also highly functional and user-friendly.
            </p>
            <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px' }}>
              We begin by gaining a deep understanding of your business goals, target audience, and brand identity. This allows us to craft a customized web design strategy that aligns with your objectives and resonates with your audience.
            </p>
            <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px' }}>
              Our team of experienced designers and developers then brings your vision to life using the latest design trends and technologies. From responsive layouts to seamless navigation, we ensure that every aspect of your website is optimized for success.
            </p>
            <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '20px' }}>
              Whether you're a small business looking to establish your online presence or a large corporation in need of a website redesign, we're here to deliver results that exceed your expectations.
            </p>
          </div>
        </div>
      </section>
      <section style={{ marginBottom: '30px' }}>
        <h2 style={{ color: '#007bff', marginBottom: '20px' }}>Our Web Design Services</h2>
        <ul className="list-group">
          <li className="list-group-item">Custom Website Design</li>
          <li className="list-group-item">Responsive Web Development</li>
          <li className="list-group-item">E-commerce Solutions</li>
          <li className="list-group-item">Content Management Systems</li>
          <li className="list-group-item">Website Maintenance</li>
        </ul>
      </section>
      <section>
        <h2 style={{ color: '#007bff', marginBottom: '20px' }}>Why Choose Us for Your Web Design Needs?</h2>
        <ul className="list-group">
          <li className="list-group-item">Experience: With over a decade of experience in web design, we have the expertise to tackle projects of any size and complexity.</li>
          <li className="list-group-item">Creative Excellence: Our team of designers combines creativity with technical proficiency to deliver visually stunning websites that leave a lasting impression.</li>
          <li className="list-group-item">Client-Centric Approach: We believe in open communication and collaboration with our clients every step of the way, ensuring that their vision is brought to life.</li>
          <li className="list-group-item">Proven Results: Our track record speaks for itself. We've helped numerous clients achieve their online goals, from increased traffic to higher conversions.</li>
        </ul>
      </section>
    </div>
  );
}

export default WebDesignPage;
