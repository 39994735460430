import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-info">
                <h3> <b style={{ color: '#ff4a17', fontFamily: 'Dancing Script, cursive', fontSize: '150%' }}>C</b>ode <b style={{ color: '#ff4a17', fontFamily: 'Dancing Script, cursive', fontSize: '150%' }}>M</b>ake</h3>
                <p>
                  SR.no.F.no.05, Shrikripa Building <br />
                  Dhankawadi Pune 411043<br /><br />
                  <strong>Phone:</strong> +91 9975072250<br />
                  <strong>Email:</strong> hr@codemake.in<br />info@codemake.in<br />
                </p>
                <div className="social-links mt-3">
                  <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                  <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                  <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                  <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
                  <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                </div>
              </div>
            </div>

           <div className="col-lg-2 col-md-6 footer-links">
  <h4>Useful Links</h4>
  <ul>
    <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
    <li><i className="bx bx-chevron-right"></i> <Link to="/services">Services</Link></li>
    <li><i className="bx bx-chevron-right"></i> <Link to="/Terms-of-service">Terms of service</Link></li>
    <li><i className="bx bx-chevron-right"></i> <Link to="/Privacy-policy">Privacy policy</Link></li>
  </ul>
</div>

<div className="col-lg-3 col-md-6 footer-links">
  <h4>Our Services</h4>
  <ul>
    <li><i className="bx bx-chevron-right"></i> <Link to="/Web-Design">Web Design</Link></li>
    <li><i className="bx bx-chevron-right"></i> <Link to="/Web-Development">Web Development</Link></li>
    <li><i className="bx bx-chevron-right"></i> <Link to="/Product-Management">Product Management</Link></li>
    <li><i className="bx bx-chevron-right"></i> <Link to="/Marketing">Marketing</Link></li>
    <li><i className="bx bx-chevron-right"></i> <Link to="/Graphic-Design">Graphic Design</Link></li>
  </ul>
</div>

<div className="col-lg-4 col-md-6 footer-newsletter">
  <h4>Our Newsletter</h4>
  <p>Our newsletter delivers company updates, industry insights, featured services, client success stories, upcoming events, expert tips, employee spotlights, customer testimonials, community involvement, and clear calls to action.</p>
  <form action="" method="post">
    <input type="email" name="email" /><input type="submit" value="Subscribe" />
  </form>
</div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>CodeMake</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href='#'>CodeMake</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
