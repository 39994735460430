import React from 'react';

const SkillEnhancementWorkshops = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4" style={{ color: '#19c3f0',margin:'4em' }}>Skill Enhancement Workshops</h2>
      <div className="row">
        <div className="col-md-3">
          <div className="card mb-4" style={{ border: '2px solid #673AB7', borderRadius: '8px', transition: 'all 0.3s ease' }}>
            <div className="card-body">
              <h3 className="card-title" style={{ color: '#673AB7' }}>Communication Skills Workshop</h3>
              <p className="card-text">Enhance your verbal and written communication skills through interactive exercises and practical examples.</p>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Date: April 15, 2024</li>
                <li>Duration: 3 hours</li>
                <li>Instructor: John Doe</li>
                <li>Effective communication techniques</li>
                <li>Public speaking practice</li>
                <li>Business writing skills</li>
                <li>Active listening strategies</li>
                <li>Non-verbal communication</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card mb-4" style={{ border: '2px solid #FF5722', borderRadius: '8px', transition: 'all 0.3s ease' }}>
            <div className="card-body">
              <h3 className="card-title" style={{ color: '#FF5722' }}>Time Management Workshop</h3>
              <p className="card-text">Learn effective time management strategies to improve productivity and reduce stress.</p>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Date: April 20, 2024</li>
                <li>Duration: 4 hours</li>
                <li>Instructor: Jane Smith</li>
                <li>Setting SMART goals</li>
                <li>Prioritizing tasks</li>
                <li>Overcoming procrastination</li>
                <li>Delegating responsibilities</li>
                <li>Time blocking techniques</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card mb-4" style={{ border: '2px solid #009688', borderRadius: '8px', transition: 'all 0.3s ease' }}>
            <div className="card-body">
              <h3 className="card-title" style={{ color: '#009688' }}>Leadership Development Seminar</h3>
              <p className="card-text">Learn essential leadership skills and strategies to inspire and motivate your team towards success.</p>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Date: April 25, 2024</li>
                <li>Duration: 2 hours</li>
                <li>Instructor: Emily Brown</li>
                <li>Team building exercises</li>
                <li>Conflict resolution techniques</li>
                <li>Goal setting and performance management</li>
                <li>Decision making and problem solving</li>
                <li>Emotional intelligence in leadership</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{marginBottom:'10%'}}>
          <div className="card mb-4" style={{ border: '2px solid #607D8B', borderRadius: '8px', transition: 'all 0.3s ease' }}>
            <div className="card-body">
              <h3 className="card-title" style={{ color: '#607D8B' }}>Stress Management Workshop</h3>
              <p className="card-text">Learn effective stress management techniques to maintain a healthy work-life balance and improve overall well-being.</p>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Date: May 1, 2024</li>
                <li>Duration: 2.5 hours</li>
                <li>Instructor: Michael Johnson</li>
                <li>Understanding stress triggers</li>
                <li>Relaxation and mindfulness techniques</li>
                <li>Time management for stress reduction</li>
                <li>Healthy lifestyle choices</li>
                <li>Building resilience</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillEnhancementWorkshops;
