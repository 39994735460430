import React from 'react';

const GraphicDesignPage = () => {
  return (
    <div className="container">
      <header style={{marginTop:'8em'}}>
        <h1 style={{ textAlign: 'center', marginBottom: '30px', fontFamily: 'cursive', fontSize: '2.5rem', color: '#ff6f61' }}>Welcome to the World of Graphic Fantasies</h1>
      </header>
      <div className="row" >
        <div className="col-md-6">
          <div className="card" style={{ backgroundColor: '#f9e7d2', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)' }}>
            <div className="card-body">
              <h5 className="card-title" style={{ fontFamily: 'fantasy', fontSize: '1.8rem', color: '#292929' }}>🎨 Enchanted Emblem: A Logo Tale</h5>
              <p className="card-text" style={{ fontStyle: 'italic', color: '#666' }}>Dive into the mesmerizing story of Enchanted Emblem, a logo design crafted with the whispers of mythical creatures and the shimmer of forgotten realms. Each stroke of the brush reveals the essence of magic and mystery, beckoning adventurers to embark on a journey through the brand's fantastical world.</p>
              <p className="card-text"><small className="text-muted">Category: Logo Design</small></p>
              <a href="#" className="btn btn-primary" style={{ backgroundColor: '#ff6f61', borderColor: '#ff6f61' }}>Unravel the Tale</a>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card" style={{ backgroundColor: '#dde5b6', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)' }}>
            <div className="card-body">
              <h5 className="card-title" style={{ fontFamily: 'fantasy', fontSize: '1.8rem', color: '#292929' }}>🌟 Celestial Serenade: Poster Symphony</h5>
              <p className="card-text" style={{ fontStyle: 'italic', color: '#666' }}>Behold the Celestial Serenade, a symphony of stars and melodies entwined in a poster design that sings to the hearts of cosmic wanderers. Let the ethereal harmonies guide you through the boundless expanse of imagination, where dreams take flight on wings of stardust and wonder.</p>
              <p className="card-text"><small className="text-muted">Category: Poster Design</small></p>
              <a href="#" className="btn btn-primary" style={{ backgroundColor: '#ff6f61', borderColor: '#ff6f61' }}>Experience the Symphony</a>
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-5" style={{ textAlign: 'center', color: '#666' }}>
        <p>Explore more magical designs and unlock the secrets of creativity!</p>
      </footer>
    </div>
  );
};

export default GraphicDesignPage;
