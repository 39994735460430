import React from 'react';
import img from '../assets/img/features-1.png'

const MarketingPage = () => {
  return (
    <div className="container-fluid bg-light py-5">
      <div className="row" style={{marginTop:'8em'}}>
        <div className="col-md-6 text-center">
          <img src={img} alt="Marketing" className="img-fluid rounded-circle mb-4" />
        </div>
        <div className="col-md-6">
          <h1 className="display-4 text-primary mb-4">Supercharge Your Business with Effective Marketing Strategies</h1>
          <p className="lead mb-4">At CodeMake Marketing, we specialize in delivering innovative marketing solutions tailored to your business needs. From digital marketing to content creation, we've got you covered!</p>
          <p className="mb-4">Our team of experts will work closely with you to develop and execute result-driven marketing campaigns that drive growth, increase brand awareness, and boost revenue.</p>
          <button className="btn btn-primary btn-lg">Get Started</button>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6">
          <h2 className="text-primary mb-4">Our Services</h2>
          <ul className="list-group mb-4">
            <li className="list-group-item">Search Engine Optimization (SEO)</li>
            <li className="list-group-item">Social Media Marketing</li>
            <li className="list-group-item">Content Creation and Strategy</li>
            <li className="list-group-item">Email Marketing</li>
            <li className="list-group-item">Pay-Per-Click (PPC) Advertising</li>
          </ul>
        </div>
        <div className="col-md-6">
          <h2 className="text-primary mb-4">Why Choose Us?</h2>
          <p className="mb-4">- Proven track record of delivering measurable results</p>
          <p className="mb-4">- Creative and strategic approach to marketing</p>
          <p className="mb-4">- Dedicated team of experienced professionals</p>
          <p className="mb-4">- Customized solutions tailored to your business goals</p>
        </div>
      </div>
    </div>
  );
}

export default MarketingPage;
