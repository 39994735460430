import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col">
          <h2 style={{ color: '#333', margin: '3em 0 20px 0' }}>Terms of Service</h2>
          <p>
            These terms and conditions outline the rules and regulations for the use of Our Company's Website, located at www.example.com.
          </p>
          <p>
            By accessing this website we assume you accept these terms and conditions. Do not continue to use Our Company's website if you do not agree to take all of the terms and conditions stated on this page.
          </p>
          <p>
            We employ the use of cookies. By accessing Our Company's website, you agreed to use cookies in agreement with the Our Company's Privacy Policy.
          </p>
          <p>
            Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
          </p>
          <p>
            Unless otherwise stated, Our Company and/or its licensors own the intellectual property rights for all material on Our Company. All intellectual property rights are reserved. You may access this from Our Company for your own personal use subjected to restrictions set in these terms and conditions.
          </p>
          <p>
            You must not:
          </p>
          <ul>
            <li>Republish material from Our Company</li>
            <li>Sell, rent or sub-license material from Our Company</li>
            <li>Reproduce, duplicate or copy material from Our Company</li>
            <li>Redistribute content from Our Company</li>
          </ul>
          <p>
            This Agreement shall begin on the date hereof.
          </p>
          <p>
            Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Our Company does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Our Company, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Our Company shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
          </p>
          <p style={{ fontStyle: 'italic' }}>
            Please review our Terms of Service carefully before using our website. By using or accessing the Service, you agree to be bound by these Terms.
          </p>
          <button className="btn btn-primary"style={{marginBottom:'20px'}}>Accept Terms</button>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;
