import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Footer from './components/Footer';
import Services from './components/Services';
import Product from './components/Product';
import Contact from './components/Contact';
import D1 from './Dropdown/D1';
import DD1 from './Dropdown/DD1';
import DD2 from './Dropdown/DD2';
import DD3 from './Dropdown/DD3';
import DD4 from './Dropdown/DD4';
import DD5 from './Dropdown/DD5';
import D2 from './Dropdown/D2';
import D3 from './Dropdown/D3';
import D4 from './Dropdown/D4';
import F1 from './Footer/F1';
import F2 from './Footer/F2';
import F3 from './Footer/F3';
import F4 from './Footer/F4';
import F5 from './Footer/F5';
import F6 from './Footer/F6';
import F7 from './Footer/F7';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap-icons/bootstrap-icons.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/glightbox/css/glightbox.min.css';
import './assets/vendor/remixicon/remixicon.css';
import './assets/vendor/swiper/swiper-bundle.min.css';

import './App.css';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/current-job-openings" element={<D1/>} />
        <Route path="/onboarding-process" element={<DD1/>} />
        <Route path="/technical-training" element={<DD2/>} />
        <Route path="/professional-development" element={<DD3/>} />
        <Route path="/skill-enhancement-workshops" element={<DD4/>} />
        <Route path="/continuing-education-support" element={<DD5/>} />
        <Route path="/employee-benefits" element={<D2/>} />
        <Route path="/company-culture" element={<D3/>} />
        <Route path="/application-process" element={<D4/>} />
        <Route path="/Terms-of-service" element={<F1/>} />
        <Route path="/Privacy-policy" element={<F2/>} />
        <Route path="/Web-Design" element={<F3/>} />
        <Route path="/Web-Development" element={<F4/>} />
        <Route path="/Product-Management" element={<F5/>} />
        <Route path="/Marketing" element={<F6/>} />
        <Route path="/Graphic-Design" element={<F7/>} />
      </Routes>
      <Footer />
    </Router>
  );
}


function Home() {
  return (
    <>
      <Hero />
      <About />
    </>
  );
}

export default App;