import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col">
          <h2 className="text-primary mb-4" style={{marginTop:'3em'}}>Privacy Policy</h2>
          <p>
            Protecting your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your personal information on our website, www.example.com, and other associated platforms.
          </p>
          <p>
            We only collect personal information that is necessary to provide you with our services and improve your experience. This may include your name, email address, contact information, and any other information you voluntarily provide to us.
          </p>
          <p>
            We use industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet, or method of electronic storage, is 100% secure.
          </p>
          <p>
            Our website may contain links to other sites that are not operated by us. We have no control over the content and privacy practices of these sites and encourage you to review their privacy policies before providing any personal information.
          </p>
          <p>
            By using our website, you consent to the terms of this Privacy Policy. If you have any questions or concerns about our privacy practices or your personal information, please contact us.
          </p>
          <p>
            This Privacy Policy is effective as of 1 January 2024 and may be updated from time to time. Any changes will be posted on this page, and your continued use of our website after the changes have been made will constitute acceptance of those changes.
          </p>
          <h4 className="text-primary mt-4">Additional Information:</h4>
          <ul>
            <li><strong>Log Data:</strong> We collect information that your browser sends whenever you visit our website ("Log Data"). This may include your computer's Internet Protocol (IP) address, browser type, browser version, the pages of our site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</li>
            <li><strong>Cookies:</strong> We use cookies to improve your experience on our website. You can disable cookies in your browser settings, but please note that some parts of our site may not function properly if you do.</li>
            <li><strong>Service Providers:</strong> We may engage third-party companies and individuals to assist with our website and services. These third parties have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose.</li>
            <li><strong>Children's Privacy:</strong> Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can take appropriate action.</li>
            <li style={{marginBottom:'3em'}}><strong>Changes to This Privacy Policy:</strong> We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</li>
          </ul>
          {/* Include more privacy policy details here */}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
