import React from 'react';
import img from '../assets/img/about.jpg';
import img1 from '../assets/img/about-boxes-1.jpg';
import img2 from '../assets/img/about-boxes-2.jpg';
import img3 from '../assets/img/about-boxes-3.jpg';
import c1 from '../assets/img/clients/client-1.png';
import c2 from '../assets/img/clients/client-2.png';
import c3 from '../assets/img/clients/client-3.png';
import c4 from '../assets/img/clients/client-4.png';
import c5 from '../assets/img/clients/client-5.png';
import c6 from '../assets/img/clients/client-6.png';

function About() {
  return (
    <div>
      <section id="about" className="about">
        <div className="container">

          <div className="row justify-content-end">
            <div className="col-lg-11">
              <div className="row justify-content-end">

                <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-emoji-smile"></i>
                    <span data-purecounter-start="0" data-purecounter-end="125" data-purecounter-duration="1" className="purecounter"></span>
                    <p>Happy Clients</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-journal-richtext"></i>
                    <span data-purecounter-start="0" data-purecounter-end="85" data-purecounter-duration="1" className="purecounter"></span>
                    <p>Projects</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-clock"></i>
                    <span data-purecounter-start="0" data-purecounter-end="35" data-purecounter-duration="1" className="purecounter"></span>
                    <p>Years of experience</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
                  <div className="count-box">
                    <i className="bi bi-award"></i>
                    <span data-purecounter-start="0" data-purecounter-end="48" data-purecounter-duration="1" className="purecounter"></span>
                    <p>Awards</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-lg-6 video-box align-self-baseline">
              <img src={img} className="img-fluid" alt="" />
              <a href="" className="glightbox mb-4"></a>
            </div>

            <div className="col-lg-6 pt-3 pt-lg-0 content">
              <h3>Empowering Businesses Through Innovation: About Us</h3>
              <p className="fst-italic">
                Welcome to our corner of the digital world, where technology meets innovation to propel businesses forward. Founded with a vision to revolutionize the IT landscape, we are dedicated to delivering cutting-edge solutions that empower organizations to thrive in today's dynamic environment.
              </p>
              <ul>
                <li><i className="bx bx-check-double"></i> Prioritize collaboration with clients to deeply understand their needs and challenges.</li>
                <li><i className="bx bx-check-double"></i> Unwavering commitment to quality in all aspects of our work.</li>
                <li><i className="bx bx-check-double"></i> Passionate about innovation and leveraging cutting-edge technologies.</li>
                <li><i className="bx bx-check-double"></i> Dedicated to exceeding client expectations and delivering exceptional value.</li>
              </ul>
              <p>
                At our core, we are more than just an IT solutions provider – we are your strategic partner in navigating the digital landscape and achieving unparalleled success. Join us as we embark on a journey to transform your business and unlock its full potential in the digital age.
              </p>
            </div>

          </div>

        </div>
      </section>

      <section id="about-boxes" className="about-boxes">
        <div className="container" >
          <div className="row">

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" >
              <div className="card">
                <img src={img1} className="card-img-top" alt="..." />
                <div className="card-icon">
                  <i className="ri-brush-4-line"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title"><a href="">Our Mission</a></h5>
                  <p className="card-text">"Our mission is to empower businesses through innovative technology solutions, driving growth, efficiency, and digital transformation. We are committed to delivering exceptional value by fostering collaboration, leveraging cutting-edge technologies, and exceeding client expectations. Our goal is to be the trusted partner in achieving sustainable success in today's dynamic digital landscape."</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" >
              <div className="card">
                <img src={img2} className="card-img-top" alt="..." />
                <div className="card-icon">
                  <i className="ri-calendar-check-line"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title"><a href="">Our Plan</a></h5>
                  <p className="card-text">"Our plan is to deliver excellence through continuous innovation, building strong partnerships with clients, investing in talent, expanding into new markets, integrating sustainable practices, and maintaining operational agility. By executing this plan, we aim to be recognized as a leading force in the IT industry, driving transformative change and delivering value to businesses globally."</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch" >
              <div className="card">
                <img src={img3} className="card-img-top" alt="..." />
                <div className="card-icon">
                  <i className="ri-movie-2-line"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title"><a href="">Our Vision</a></h5>
                  <p className="card-text">"Our vision is to be a global leader in transformative technology solutions, driving innovation and empowering businesses to thrive in the digital age. We envision a world where every organization leverages cutting-edge technology to unlock their full potential and achieve sustainable success."</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section id="clients" className="clients">
        <div className="container" >
          <div className="row">

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src={c1} className="img-fluid" alt="" />
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src={c2} class="img-fluid" alt=""/>
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={c3} class="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={c4} class="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={c5} class="img-fluid" alt=""/>
          </div>

          <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
            <img src={c6} class="img-fluid" alt=""/>
          </div>


          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
