import React from 'react';

const EmployeeBenefits = () => {
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4" style={{ color: '#FF9800',margin:'4em' }}>Employee Benefits</h2>
      <div className="row">
        <div className="col-md-6">
          <ul className="list-group">
            <li className="list-group-item" style={{ backgroundColor: '#FFC107', color: '#333', margin: '10px', padding: '15px' }}>
              <h3 className="mb-1">Health Insurance</h3>
              <p className="mb-1">We offer comprehensive health insurance coverage for all employees and their dependents.</p>
              <ul className="mb-0" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Medical, dental, and vision coverage</li>
                <li>Flexible spending accounts (FSA)</li>
                <li>Wellness programs and resources</li>
                <li>Health savings accounts (HSA)</li>
                <li>Prescription drug coverage</li>
              </ul>
            </li>
            <li className="list-group-item" style={{ backgroundColor: '#03A9F4', color: '#fff', margin: '10px', padding: '15px' }}>
              <h3 className="mb-1">Paid Time Off (PTO)</h3>
              <p className="mb-1">Employees receive paid time off for vacation, sick leave, and personal days.</p>
              <ul className="mb-0" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Flexible vacation policy</li>
                <li>Generous sick leave benefits</li>
                <li>Personal days for family events and emergencies</li>
                <li>Parental leave options</li>
                <li>Holidays and company closures</li>
              </ul>
            </li>
            <li className="list-group-item" style={{ backgroundColor: '#4CAF50', color: '#fff', margin: '10px', padding: '15px' }}>
              <h3 className="mb-1">Employee Assistance Program (EAP)</h3>
              <p className="mb-1">Our EAP provides confidential counseling and support services for employees and their families.</p>
              <ul className="mb-0" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Counseling for personal and work-related issues</li>
                <li>Legal and financial consultation</li>
                <li>Wellness resources and referrals</li>
                <li>24/7 crisis support</li>
                <li>Online resources and tools</li>
              </ul>
            </li>
            {/* Add more list-group-item elements here */}
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-group">
            <li className="list-group-item" style={{ backgroundColor: '#E91E63', color: '#fff', margin: '10px', padding: '15px' }}>
              <h3 className="mb-1">Retirement Plans</h3>
              <p className="mb-1">We provide various retirement plans to help employees plan for their future.</p>
              <ul className="mb-0" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>401(k) plans with employer match</li>
                <li>IRA options</li>
                <li>Retirement planning resources</li>
                <li>Financial counseling services</li>
                <li>Flexible contribution options</li>
              </ul>
            </li>
            <li className="list-group-item" style={{ backgroundColor: '#FF5722', color: '#fff', margin: '10px', padding: '15px' }}>
              <h3 className="mb-1">Education Assistance</h3>
              <p className="mb-1">We support employee education through tuition reimbursement and professional development programs.</p>
              <ul className="mb-0" style={{ listStyleType: 'none', paddingLeft: 0 }}>
                <li>Tuition reimbursement for approved courses</li>
                <li>Professional development workshops</li>
                <li>Certification exam fee reimbursement</li>
                <li>Access to online learning platforms</li>
                <li>Skills training and career development</li>
              </ul>
            </li>
            {/* Add more list-group-item elements here */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EmployeeBenefits;
