import React from 'react';

function Contact() {
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up" style={{ marginTop: '80px' }}>

        <div className="section-title" style={{ textAlign: 'center' }}>
          <h2>Contact</h2>
          <p>Contact Us</p>
        </div>
        <div className="row">

          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12">
                <div className="info-box">
                  <i className="bx bx-map"></i>
                  <h3>Our Address</h3>
                  <p>SR.no.F.no.05,Shrikripa Building <br />
                    Dhankawadi Pune 411043</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>hr@codemake.in<br />info@codemake.in</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <p>+91 9975072250<br /><br /></p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-4 mt-lg-0">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

          <div style={{ textAlign: 'center', marginTop: '5%' }}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6364.577304778076!2d73.84896743759514!3d18.465518134313385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eab786f132dd%3A0x7e73bc336a4a20f3!2sDhankawadi%2C%20Pune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1707982987641!5m2!1sen!2sin" width="600" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>

        </div>

      </div>
    </section>
  );
}

export default Contact;
