import React from 'react';
import imageUrl from '../assets/img/download.jpg';

const WebDevelopmentPage = () => {
  // Example image URL from Google (replace this with your desired image URL)

  return (
    <div className="container mt-4 mb-4">
      <div className="row">
        <div className="col-md-6">
          <h1 className="text-primary mb-4" style={{marginTop:'3em'}}>Web Development Services</h1>
          <section className="shadow p-4 rounded" style={{ backgroundColor: '#f8f9fa' }}>
            <h2 style={{ color: '#007bff', marginBottom: '20px' }}>Our Approach to Web Development</h2>
            <p style={{ fontSize: '18px', lineHeight: '1.6', marginBottom: '20px' }}>
              At Our Company, we believe that effective web development is about more than just writing code. It's about understanding your business objectives and translating them into innovative digital solutions.
            </p>
            <p style={{ fontSize: '18px', lineHeight: '1.6', marginBottom: '20px' }}>
              We follow a collaborative approach, working closely with our clients to define project requirements and develop a strategic roadmap. This allows us to deliver tailored solutions that address your specific needs and goals.
            </p>
            <p style={{ fontSize: '18px', lineHeight: '1.6', marginBottom: '20px' }}>
              Our team of experienced developers stays updated on the latest technologies and best practices to ensure that your web applications are scalable, secure, and future-proof.
            </p>
            <p style={{ fontSize: '18px', lineHeight: '1.6', marginBottom: '20px' }}>
              Whether you need a simple website, a custom web application, or an e-commerce platform, we have the expertise to bring your vision to life and drive your online success.
            </p>
          </section>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <img
            src={imageUrl}
            alt="Web Development"
            className="img-fluid rounded shadow"
            style={{ maxWidth: '100%', height: '20em' }}
          />
        </div>
      </div>
      <section className="mt-5 pt-4 pb-4" style={{ backgroundColor: '#e9ecef', borderRadius: '8px' }}>
        <h2 style={{ color: '#007bff', marginBottom: '20px', textAlign: 'center' }}>Our Web Development Services</h2>
        <ul className="list-group list-group-flush">
          <li className="list-group-item" style={{ backgroundColor: '#28a745', color: '#fff' }}>Custom Web Application Development</li>
          <li className="list-group-item" style={{ backgroundColor: '#dc3545', color: '#fff' }}>Frontend Development</li>
          <li className="list-group-item" style={{ backgroundColor: '#ffc107', color: '#fff' }}>Backend Development</li>
          <li className="list-group-item" style={{ backgroundColor: '#17a2b8', color: '#fff' }}>API Integration</li>
          <li className="list-group-item" style={{ backgroundColor: '#6610f2', color: '#fff' }}>Database Design and Optimization</li>
        </ul>
      </section>
    </div>
  );
}

export default WebDevelopmentPage;
