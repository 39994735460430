import React from 'react';

const TechnicalTraining = () => {
  return (
    <div className="container mt-5">
      <div className="card" style={{ margin: '12em 2em 15em 2em'}}>
        <div className="card-body">
          <h2 className="text-center mb-4" style={{ color: '#007bff' }}>Technical Training Process</h2>
          <ol className="training-steps">
            <li>
              <h3 style={{ color: '#28a745' }}>Needs Assessment</h3>
              <p>Assess the technical skills and knowledge required for the new employee's role. Identify any gaps in their skills or knowledge that need to be addressed through training.</p>
              <ul>
                <li>Conduct interviews with stakeholders to determine technical requirements.</li>
                <li>Review job description and identify key technical competencies.</li>
                <li>Evaluate the new employee's existing skills through assessments or tests.</li>
              </ul>
            </li>
            <li>
              <h3 style={{ color: '#dc3545' }}>Training Plan Development</h3>
              <p>Develop a customized training plan based on the needs assessment. Determine the topics and modules to be covered in the training program. Identify the resources, materials, and tools needed for training.</p>
              <ul>
                <li>Define learning objectives for each training module.</li>
                <li>Create training materials such as presentations, guides, and exercises.</li>
                <li>Identify trainers or subject matter experts to deliver the training.</li>
              </ul>
            </li>
            <li>
              <h3 style={{ color: '#ffc107' }}>Orientation Session</h3>
              <p>Conduct an orientation session to introduce the new employee to the training program. Provide an overview of the training objectives, schedule, and expectations.</p>
              <ul>
                <li>Welcome the new employee to the training program.</li>
                <li>Provide an overview of the training schedule and topics.</li>
                <li>Set expectations for participation and engagement.</li>
              </ul>
            </li>
            {/* Include other steps */}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TechnicalTraining;
