import React from 'react';

const ProfessionalDevelopment = () => {
  const backgroundStyle = {
    backgroundImage: `url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAJQA7AMBIgACEQEDEQH/xAAaAAEBAQEBAQEAAAAAAAAAAAADBAIBAAUG/8QAHhABAQEAAwEBAQEBAAAAAAAAAAIBAxESIRMxQWH/xAAYAQEBAQEBAAAAAAAAAAAAAAAAAQIDBf/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A+ZhMHhJeo8sklkcllAklwcmkG5LOMSScBvG8xycbzBXsx3p3MdQZ6c3G3NxQW4KsPuCoAXgaxRYbwE14nvFPJie8BLeA5MVcmJr/AKglsF4pvAXitJrwNnsN4zVBQ6LQ6QFTOt0xqLH7XCyLCy6uRJw0in/CygWSyKDSBZwksSSQJOExmW8RXcx3p3HegZ6c1vpygFo6w2joE9YG1FYG8UTcmJ7VXie8BJyJ+TFd4m5MQS3ie8VXie1aTWC1N4C2aAoVYasFSKLWNJWMbn1Ffs8JIpLLo5GkshnSwBoNIJNIHkshk0illvGJJiDeOuY6Ljz2u65oDpisJrFCAvA1h6FYJrxPeKbT8gJuTE3JirkxPyAkvE9qr/0F4qpbz4C1N4C2aqexUexUihpnolMIP10lnQSSddHM86aNTzpp0DyaU86adBRJpTzpY0U8lwE6WdQLjWfwct5osde1zt7dByh63usUFFQbNWhsQNpuRTabkBPyJ+TPqnkT2CbkxPeKeTE9qqe09qLDaVQVgaw9CplQ6PS7jHQP086TNBmknW3NROljU06aNBTGljU00aNFUxpppNGlnQUzpJ1POkykFGa1mhyms0WF7e7H6d9A1rFa5usboPVoa1utFWiDvU9mvQXvwA8iez3qe/6AORPaiwWqp7wNGv8AgaZqhsNGsVIoqY1umNQfemiZSedJNOjCmaLNJZ0s0CqdNNJJos0CuaNNJJok2grmi5aSbJlgqymsr6nymssIo9Pegeu3fQpdpnaHtM7YjdaKtc2h1QPXob12qDegzegvW70F6KOw23ehrRRWGy3oa1kFQqJWioVimNa1ntB9XKbyk+U3lNsqZs00kmiTQmK5os0kmyTYYsmiTSSb+EmwxZNEm0c2TKDFeW1lpMtv38FU+3ff/U2Xj3sFPtnbB7c21DVY6oe2xtoNVQ6pmqFVivXQbp2qDVJo5dBrXaoV0gzWhrWroNaKzWjrXa0daiuVrGvVrG6GPo5reanmm8pvWVE0WaSzRJoFM0SbS5RMpUVzXxubSzbeWgrm285EmW1nIorzkby0mW7lporzke9pct39DRT7c/RP7c/RFUbbFWHeRjbAtWOrHVjq0G6sV0zViqxXaoVU5VCqgeqh1T1UKtTVkeqh1rm0xVM2tY7WsbrNaxtJo+hmt5QO2s10YUTTeanym8oRRla3lJsomaoebJlpsprKNRTltZabLdywVZbXtL7dyxVP6O/om9u+kFH6Oe0/tz2CjbZ2w7fxjbFNtjqx7bG2BNoVV9Y22KpFaqxVTNUOqSq1VDqnKoVUzaru0xVOVQ9oWRraY2mdpntnWsfS7dzXnnZxbxrN6deEdzW8154Gs13vXXlHc1rt54He3e3nkHHe9eeB7vXHnhXN3XN154Gd3WN155BitHuvPI1GN0da88ijrR7rzyNRjd+MbrzyNMay88wr/9k=')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
  };

  return (
    <div className="container mt-5" style={backgroundStyle}>
      <h2 className="text-center mb-4" style={{ color: '#194574' ,marginTop:'5em',padding:'20px'}}>Professional Development Opportunities</h2>
      <ul className="list-group">
        <li className="list-group-item">
          <h3 style={{ color: '#28a745' }}>Training and Workshops</h3>
          <p>Offer training sessions and workshops on relevant topics such as leadership, communication skills, time management, and industry-specific skills.</p>
          <ul style={{ paddingLeft: '20px' }}>
            <li>Provide hands-on training and interactive workshops led by experienced professionals.</li>
            <li>Offer online courses and resources for self-paced learning.</li>
            <li>Customize training programs to address specific skill gaps and employee needs.</li>
            <li>Include practical exercises and real-world scenarios to enhance learning.</li>
          </ul>
        </li>
        <li className="list-group-item">
          <h3 style={{ color: '#dc3545' }}>Certification Programs</h3>
          <p>Support employees in obtaining professional certifications related to their field or industry.</p>
          <ul style={{ paddingLeft: '20px' }}>
            <li>Provide financial assistance or reimbursements for certification exams and study materials.</li>
            <li>Offer study groups or workshops to prepare employees for certification exams.</li>
            <li>Recognize and reward employees who successfully obtain certifications.</li>
            <li>Partner with recognized certification providers to offer specialized training programs.</li>
          </ul>
        </li>
        <li className="list-group-item">
          <h3 style={{ color: '#ffc107' }}>Mentorship and Coaching</h3>
          <p>Pair employees with experienced mentors within the organization. Offer coaching sessions to help employees set and achieve professional development goals.</p>
          <ul style={{ paddingLeft: '20px' }}>
            <li>Match employees with mentors based on their career goals and interests.</li>
            <li>Provide guidance and support for career advancement and skill development.</li>
            <li>Offer regular coaching sessions to discuss progress, challenges, and strategies for growth.</li>
            <li>Encourage open communication and feedback between mentors and mentees.</li>
          </ul>
        </li>
        {/* Include other opportunities */}
      </ul>
    </div>
  );
};

export default ProfessionalDevelopment;
