import React, { useState } from 'react';

const ApplicationForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    resume: null,
    coverLetter: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send form data to server or perform any desired action
    console.log(formData);
    // Reset form fields
    setFormData({
      fullName: '',
      email: '',
      resume: null,
      coverLetter: ''
    });
  };

  return (
    <div style={{ maxWidth: '500px', margin: 'auto' }}>
      <div className="process-info" style={{ marginBottom: '20px' }}>
        <h2 style={{ textAlign: 'center',marginTop:'4em' }}>Application Process</h2>
        <p style={{ textAlign: 'center' }}>
"Welcome to the application process. Please fill out this form accurately and submit by the deadline. Your privacy is respected. Thank you for your cooperation."</p>
      </div>
      <form className="p-4" onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="fullName" className="form-label">Full Name</label>
          <input
            type="text"
            className="form-control"
            id="fullName"
            placeholder="Enter your full name"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            style={{ width: '100%' }}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email Address</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Enter your email address"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            style={{ width: '100%' }}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="resume" className="form-label">Upload Resume</label>
          <input
            type="file"
            className="form-control"
            id="resume"
            name="resume"
            onChange={handleFileChange}
            style={{ width: '100%' }}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="coverLetter" className="form-label">Cover Letter</label>
          <textarea
            className="form-control"
            id="coverLetter"
            rows="4"
            placeholder="Enter your cover letter"
            name="coverLetter"
            value={formData.coverLetter}
            onChange={handleInputChange}
            style={{ width: '100%' }}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary" style={{ width: '100%',marginBottom:'10%' }}>Submit Application</button>
      </form>
    </div>
  );
};

export default ApplicationForm;
