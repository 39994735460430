import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center justify-content-between">
        <h1 className="logo">
          <a href="/">
            <i className="bi bi-code-slash"></i> 
            <b style={{ color: '#ff4a17', fontFamily: 'Dancing Script', fontSize: '150%' }}>C</b>ode  
            <b style={{ color: '#ff4a17', fontFamily: 'Dancing Script', fontSize: '150%' }}>M</b>ake
          </a>
        </h1>

        <div className="mobile-nav-toggle" onClick={toggleMobileMenu}>
          <i className="bi bi-list"></i>
        </div>

        <nav id="navbar" className={`navbar ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
          <ul>
            <li><Link to="/" className="nav-link scrollto active" onClick={closeMobileMenu}>Home</Link></li>
            <li><Link to="/services" className="nav-link scrollto" onClick={closeMobileMenu}>Services</Link></li>
            <li><Link to="/product" className="nav-link scrollto" onClick={closeMobileMenu}>Product</Link></li>
            <li className="dropdown">
              <a href="#"><span>Careers</span> <i className="bi bi-chevron-down"></i></a>
              <ul>
                <li><Link to="/current-job-openings" onClick={closeMobileMenu}>Current Job Openings</Link></li>
                <li className="dropdown">
                  <a href="#"><span>Training and Development</span> <i className="bi bi-chevron-right"></i></a>
                  <ul>
                    <li><Link to="/onboarding-process" onClick={closeMobileMenu}>Onboarding Process</Link></li>
                    <li><Link to="/technical-training" onClick={closeMobileMenu}>Technical Training</Link></li>
                    <li><Link to="/professional-development" onClick={closeMobileMenu}>Professional Development Opportunities</Link></li>
                    <li><Link to="/skill-enhancement-workshops" onClick={closeMobileMenu}>Skill Enhancement Workshops</Link></li>
                    <li><Link to="/continuing-education-support" onClick={closeMobileMenu}>Continuing Education Support</Link></li>
                  </ul>
                </li>
                <li><Link to="/employee-benefits" onClick={closeMobileMenu}>Employee Benefits</Link></li>
                <li><Link to="/company-culture" onClick={closeMobileMenu}>Company Culture</Link></li>
                <li><Link to="/application-process" onClick={closeMobileMenu}>Application Process</Link></li>
              </ul>
            </li>
            <li><Link to="/contact" className="nav-link scrollto" onClick={closeMobileMenu}>Contact</Link></li>
            <li><Link to="/" className="getstarted scrollto">Get Started</Link></li>
          </ul>
        </nav>

      </div>
    </header>
  );
}

export default Header;
