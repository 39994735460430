import React from 'react';

const ContinuingEducationSupport = () => {
  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4" style={{ color: '#ce00ff91',margin:'4em' }}>Continuing Education Support</h1>
      <div className="row">
        <div className="col-md-6">
          <div className="jumbotron ce-support-jumbotron" style={{ backgroundColor: '#673AB7', color: 'white', textAlign: 'left' ,padding:'10%' ,margin:'2%'}}>
            <h3 className="display-5">Tuition Reimbursement Program</h3>
            <p className="lead">Our company offers a tuition reimbursement program to support employees pursuing further education related to their field of work.</p>
            <hr className="my-4" />
            <ul className="list-unstyled">
              <li>Reimbursement for approved courses and degree programs</li>
              <li>Flexible eligibility criteria</li>
              <li>Financial assistance for tuition, fees, and course materials</li>
              <li>Encouragement for professional development and career advancement</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="jumbotron ce-support-jumbotron" style={{ backgroundColor: '#FF5722', color: 'white', textAlign: 'left' ,padding:'15%' ,margin:'2%'}}>
            <h3 className="display-5">Professional Certification Support</h3>
            <p className="lead">We support employees in obtaining industry-recognized certifications to enhance their skills and qualifications.</p>
            <hr className="my-4" />
            <ul className="list-unstyled">
              <li>Financial assistance for certification exam fees</li>
              <li>Reimbursement for study materials and resources</li>
              <li>Guidance and resources for exam preparation</li>
              <li>Recognition and rewards for achieving certifications</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="jumbotron ce-support-jumbotron" style={{ backgroundColor: '#3F51B5', color: 'white', textAlign: 'left' ,padding:'12%' ,margin:'2%'}}>
            <h3 className="display-5">Professional Development Workshops</h3>
            <p className="lead">Attend workshops and training sessions to enhance your skills and stay updated with the latest industry trends.</p>
            <hr className="my-4" />
            <ul className="list-unstyled">
              <li>Hands-on workshops led by industry experts</li>
              <li>Interactive sessions covering a wide range of topics</li>
              <li>Networking opportunities with professionals in your field</li>
              <li>Access to resources and materials for continued learning</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="jumbotron ce-support-jumbotron" style={{ backgroundColor: '#009688', color: 'white', textAlign: 'left',padding:'10%' ,margin:'2%' }}>
            <h3 className="display-5">Educational Assistance Program</h3>
            <p className="lead">Our educational assistance program provides financial support for employees pursuing higher education degrees or certifications.</p>
            <hr className="my-4" />
            <ul className="list-unstyled">
              <li>Reimbursement for tuition fees and educational expenses</li>
              <li>Flexible scheduling options for balancing work and studies</li>
              <li>Guidance and support for selecting relevant courses</li>
              <li>Encouragement for continuous learning and skill development</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinuingEducationSupport;
