import React from 'react';

const jobOpenings = [
  { 
    title: 'Software Engineer', 
    location: 'San Francisco, CA', 
    company: 'ABC Tech Inc.', 
    description: 'We are looking for a skilled software engineer to join our team.',
    jobType: 'Full-time',
    salary: '₹80,000 - ₹100,000 per year',
    deadline: 'March 15, 2024'
  },
  { 
    title: 'Data Scientist', 
    location: 'New York, NY', 
    company: 'XYZ Analytics', 
    description: 'Seeking a talented data scientist with experience in machine learning and statistical analysis.',
    jobType: 'Contract',
    salary: '₹90,000 - ₹120,000 per year',
    deadline: 'March 20, 2024'
  },
  { 
    title: 'Product Manager', 
    location: 'Seattle, WA', 
    company: '123 Products Co.', 
    description: 'Join our dynamic product team to lead the development and launch of new products.',
    jobType: 'Part-time',
    salary: '₹60,000 - ₹80,000 per year',
    deadline: 'March 25, 2024'
  },
];

const JobListings = () => {
  return (
    <div className="container" style={{ marginTop: '7em' }}>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="job-listings">
            <h2 className="text-center mb-4">Current Job Openings</h2>
            {jobOpenings.map((job, index) => (
              <div key={index} className="job-item border p-3 mb-3">
                <h3>{job.title}</h3>
                <p><strong>Location:</strong> {job.location}</p>
                <p><strong>Company:</strong> {job.company}</p>
                <p><strong>Type:</strong> {job.jobType}</p>
                <p><strong>Salary:</strong> {job.salary}</p>
                <p><strong>Deadline:</strong> {job.deadline}</p>
                <p>{job.description}</p>
                <button className="btn btn-primary">Apply</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListings;
